import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgBeyondScienceCover from "../../assets/images/mission/beyond-science/beyond-science-cover.png"
import imgBeyondScienceCardOne from "../../assets/images/mission/beyond-science/card-img-1.png"
import imgBeyondScienceCardTwo from "../../assets/images/mission/beyond-science/card-img-2.png"
import imgBeyondScienceCardThree from "../../assets/images/mission/beyond-science/card-img-3.png"
import imgBeyondScienceBlackBg from "../../assets/images/mission/beyond-science/card-black-bg.svg"

const BeyondSciencePage = () => (
  <Layout>
    <SEO title="Beyond Science" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgBeyondScienceCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{position: "relative"}}>
          <div className="large-container pb6">
              <div className="uk-text-center">
                  <h1 className="uk-heading-large uk-text-bold uk-visible@s beyond-science" style={{marginTop: "-50px"}}>
                      <span className="text-highlight-red mr4">BEYOND 
                      </span> SCIENCE
                  </h1>
                  <h1 className="uk-heading-large uk-text-bold uk-hidden@s" style={{marginTop: "-70px"}}>
                      <span className="text-highlight-red">
                        BEYOND
                      </span>
                      <br/>SCIENCE
                  </h1>
                  <div className="w-80-ns" style={{margin: "auto"}}>
                    <p className="mv4 text-light uk-text-center">
                    Beyond the scientific objectives of the Emirates Mars Mission are its strategic objectives that revolve around human knowledge, Emirati capabilities, and global collaboration. Here is a look at what the mission will help us achieve beyond data and results.</p>
                  </div>     
              </div>
              <div className="standard-container standard-bg">
                  <div className="grid-col-3">
                    <div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardOne} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                    IMPROVE
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To Improve the quality of life on Earth by pushing our limits to make new discoveries.</p>
                            </div>
                        </div> 
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceBlackBg} style={{height: "400px", backgroundColor: "black"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                    BUILDING SCIENTIFIC KNOWLEDGE
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To build scientific knowledge because a sustainable, future-proof economy is a knowledge-based economy.</p>
                            </div>
                        </div>                            
                    </div>                               
                      <div>
                        <div className="uk-inline ma1" style={{backgroundColor: "black"}}>
                            <img src={imgBeyondScienceBlackBg} style={{height: "240px", width: "100%"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                ENCOURAGE
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To encourage global collaboration in Mars exploration.</p>
                            </div>
                        </div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardTwo} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                BUILD EMIRATI CAPABILITIES
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To build Emirati capabilities in the field of interplanetary exploration.</p>
                            </div>
                        </div>
                        <div className="uk-inline ma1" style={{backgroundColor: "black"}}>
                            <img src={imgBeyondScienceBlackBg} style={{height: "235px", width: "100%"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                INSPIRE
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To Inspire future Arab generations to pursue space science.</p>
                            </div>
                        </div>
                      </div>
                      <div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardThree} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                    ESTABLISH GLOBAL LEADERSHIP
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To demonstrate leadership in space research.</p>
                            </div>
                        </div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceBlackBg} style={{height: "400px", backgroundColor: "black"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-center uk-text-center uk-light">
                                <h3>
                                    ESTABLISH THE UAE’S POSITION
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">To establish the UAE’s position as a beacon of progress in the region.</p>
                            </div>
                        </div>
                      </div>                    
                  </div>
              </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default BeyondSciencePage
